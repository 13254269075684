<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="110px" :rules="rules">
      <el-form-item prop="account" label="登录账号">
        <el-input style="width: 500px" size="small" v-model="formData.account" placeholder="登录账号"></el-input>
      </el-form-item>
      <el-form-item prop="password" label="登录密码">
        <el-input style="width: 500px" size="small" v-model="formData.password" placeholder="8位以上包含大小写字母、特殊符号、数字组成"></el-input>
        <span style="margin-left: 10px; color: #f56c6c">8位以上包含大小写字母、特殊符号、数字组成</span>
      </el-form-item>
      <el-form-item prop="username" label="姓名">
        <el-input style="width: 500px" size="small" v-model="formData.username" placeholder="姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input style="width: 500px" size="small" v-model="formData.phone" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item prop="role_ids" label="角色">
        <SelectRole style="width: 500px" :multiple="true" v-model="formData.role_ids"></SelectRole>
      </el-form-item>
      <el-form-item label="状态">
        <el-select size="small" v-model="formData.status" placeholder="请选择">
          <el-option label="正常" :value="20"></el-option>
          <el-option label="停用" :value="10"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否同步通讯录" :rules="rules">
        <el-radio-group v-model="formData.is_address_book">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <template v-if="formData.is_address_book == 1">
        <el-form-item label="单位职务" prop="position" :rules="rules">
          <el-input style="width: 500px" v-model="formData.position" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="片区">
          <SelectDistrict v-model="formData.district_id" width="500px"></SelectDistrict>
        </el-form-item>
        <el-form-item label="街道" prop="address_name" :rules="rules">
          <el-input style="width: 500px" v-model="formData.address_name" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <el-input style="width: 500px" v-model="formData.intro" type="textarea" rows="4" size="small" placeholder="请输入"></el-input>
        </el-form-item>
      </template>

      <el-form-item label=" ">
        <el-button size="small" type="" @click="$router.back()">取消</el-button>
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import SelectRole from '@/views/components/select-role.vue'
import SelectDistrict from '@/views/components/select-district.vue'
import { addAdmin, editAdmin, getAdminDetail, getRoleList } from './api'
export default {
  name: 'Detail',
  components: { SelectRole, SelectDistrict },
  data() {
    return {
      formData: {
        account: '',
        password: '',
        username: '',
        phone: '',
        role_ids: [],
        is_super: 0,
        status: 20,
        position: '',
        district_id: '',
        address_name: '',
        intro: '',
        is_address_book: 1
      },
      rules: {
        account: { required: true, message: '登录不能为空', trigger: 'blur' },
        username: { required: true, message: '姓名不能为空', trigger: 'blur' },
        phone: [
          { required: true, message: '手机号码为空', trigger: 'blur' },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur'
          }
        ],
        password: [
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
            message: '密码由8位以上包含大小写字母、特殊符号、数字组成',
            trigger: 'blur'
          }
        ]
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getAdminDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.formData.id) {
            // 编辑
            await editAdmin(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            // 新增
            if (!this.formData.password) return this.$message.warning('密码不能为空')
            await addAdmin(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        } else {
          return false
        }
      })
    },
    async getAdminDetail() {
      this.formData = await getAdminDetail(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
